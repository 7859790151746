@import 'src/styles/mixins.scss';

.loading {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0%;
  height: 0.4rem;
  width: 100%;

  div {
    animation: loading 2s infinite ease-in-out;
    width: 100%;
    height: 100%;
    transform: translateX(-100vw);
    border-radius: 100px;

    background-image: $green; /* Old browsers */
    background-image: linear-gradient(
      to right,
      transparent,
      $blue,
      $green
    ); /* Standard syntax; must be last */
  }
}

@keyframes loading {
  0% {
    transform: translateX(-100vw);
    opacity: 1;
  }
  80% {
    transform: translateX(100vw);
  }
  81%,
  100% {
    opacity: 0;
  }
}
